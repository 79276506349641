// trackClick.js

import { authAxios } from "../config/constant";

export const trackClick = (cardLabel, mobileNumber) => {
    const data = {
      eventType : 'click',
      cardLabel : cardLabel,
      mobileNumber : mobileNumber,
    };
    const headers = {
      AuthToken: localStorage.getItem("auth_key"),
      Otp: localStorage.getItem("otp")
    }
    if (headers.AuthToken) {
      authAxios
        .post('/track_click', data, {headers : headers})
        .then((res) => {
          console.log(res);
        })
        .catch(error => console.error("Error: ", error));
    }}
  //   fetch('/api/track_click', {
  //     method: 'POST',
  //     headers: {
  //       'Content-Type': 'application/json',
  //     },
  //     body: JSON.stringify({
  //       eventType: 'click',
  //       cardLabel: cardLabel,
  //       mobileNumber: mobileNumber,
  //     }),
  //   })
  //   .then(response => response.json())
  //   .catch(error => console.error('Error:', error));
  // };
  